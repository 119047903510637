/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  @apply h-full;
}

body {
  @apply bg-[black] bg-[url('assets/images/bg.svg')] m-0 font-base text-white leading-normal text-sm 2xl:text-base;
}

body * {
  @apply tracking-[.02em];
}

input {
  @apply placeholder:text-neutral-500;
}

.sidebar {
  .active {
    > .menu-item {
      svg {
        path {
          @apply fill-primary;
        }
      }
    }
  }
  .menu-item {
    svg {
      path {
        @apply text-black-light;
      }
    }

    &.active,
    &:hover {
      svg {
        path {
          @apply fill-primary;
        }
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto;
  @apply w-0.5 h-0.5;
}

::-webkit-scrollbar-track {
  @apply rounded-md shadow-inner;
}

::-webkit-scrollbar-thumb {
  @apply bg-secondary rounded-md;
}

.common-btn {
  @apply px-4 h-10 rounded-5 transition-all;

  &.primary-btn {
    @apply bg-primary text-white hover:bg-primary/85;
  }

  &.secondary-btn {
    @apply bg-primary-light text-primary hover:bg-primary-light/85;
  }

  &:disabled {
    @apply bg-grey-50 text-grey-light cursor-not-allowed;
  }
}

.custom-select {
  &.ng-select,
  &.ng-select-focused:not(.ng-select-opened) {
    .ng-select-container {
      @apply border-[#3d3d3d] shadow-none rounded-md bg-black text-neutral-300  min-h-[40px] h-full;

      .ng-value-container {
        @apply pl-4 pr-2;

        .ng-input {
          @apply pl-4 top-2.5 translate-y-[-1/2];
        }

        .ng-placeholder {
          @apply text-neutral-500;
        }
      }
    }

    .ng-dropdown-panel {
      @apply border-grey-50;

      .ng-dropdown-panel-items {
        .ng-option {
          @apply bg-black text-white px-4 py-2;

          &.ng-option-selected {
            @apply bg-primary-light text-primary;
          }

          &.ng-option-marked {
            @apply bg-grey-50 text-black;
          }
        }
      }
    }

    .ng-arrow-wrapper {
      @apply pr-3;
    }
  }

  &.rounded {
    .ng-select-container {
      @apply rounded-md;
    }
  }

  &.field-error {
    .ng-select-container {
      @apply border-red text-red;

      .ng-arrow-wrapper {
        .ng-arrow {
          @apply border-t-red;
        }
      }
    }
  }

  &.ng-select-disabled {
    .ng-select-container {
      @apply bg-white;
    }
  }
}

ng-select.hide-arrow {
  .ng-arrow-wrapper {
    @apply hidden;
  }
}

.mat-mdc-paginator {
  &.generic-table-pagination {
    @apply bg-black-dark text-white font-base border-t border-grey-50 text-sm font-semibold pt-4;

    .mat-mdc-paginator-container {
      @apply justify-between p-0 min-h-[auto];
    }

    .mat-mdc-paginator-page-size-label {
      @apply m-0;
    }

    .mat-mdc-form-field {
      .mat-mdc-select-trigger {
        .mat-mdc-select-value {
          @apply text-white;
        }
        .mat-mdc-select-arrow {
          @apply text-white;
        }
      }
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        @apply border-white;
      }

      &.mat-focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          @apply border-white;
        }

        .mat-mdc-select-arrow {
          @apply text-white;
        }
      }
    }

    .mat-mdc-paginator-range-label {
      @apply m-0 mr-6 ml-3;
    }

    .mat-mdc-paginator-range-actions {
      .mat-mdc-icon-button {
        .mat-mdc-paginator-icon {
          fill: white;
        }
        &[disabled] {
          .mat-mdc-paginator-icon {
            fill: gray;
          }
        }
      }
    }

    .mat-mdc-button-base {
      @apply p-0 h-8 w-8;
    }
  }
}

.custom-matMenu {
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      @apply min-h-[40px];

      .mdc-list-item__primary-text {
        @apply font-base text-sm 2xl:text-base font-semibold text-grey-500;
      }
    }
  }
}

.mat-ripple {
  @apply hidden;
}

.iti {
  &.iti--separate-dial-code {
    @apply w-full font-semibold h-full;

    .iti__country {
      @apply px-3 py-2;
    }

    .iti__selected-flag {
      @apply bg-transparent pl-4 pr-2 border-r border-grey-50;
    }
  }
}

.mdc-snackbar {
  &.mat-mdc-snack-bar-container {
    --mat-snack-bar-button-color: inherit;
    .mdc-snackbar__surface {
      @apply bg-white pr-0 border-l-4;

      .mat-mdc-simple-snack-bar {
        @apply items-start;
      }

      .mdc-snackbar__label {
        @apply text-inherit font-base font-semibold;
      }

      .mdc-button {
        @apply min-h-[44px] min-w-[44px];
      }
    }
  }

  &.error-snackbar {
    .mdc-snackbar__surface {
      @apply border-red text-red;
    }
  }

  &.success-snackbar {
    .mdc-snackbar__surface {
      @apply border-green text-green;
    }
  }

  &.warning-snackbar {
    .mdc-snackbar__surface {
      @apply border-orange-400 text-orange-400;
    }
  }

  &.info-snackbar {
    .mdc-snackbar__surface {
      @apply border-blue-400 text-blue-400;
    }
  }
}

.field-lbl {
  @apply flex items-center text-grey-light mb-1 py-1 leading-5 font-semibold;
  word-break: break-word;
}

.form-field {
  @apply w-full px-4 font-semibold bg-black text-white rounded-md border outline-0 min-h-[40px] border-[#3d3d3d];

  &:disabled {
    @apply bg-white;
  }
}

.field-error {
  @apply border-red text-red;
}

.custom-input {
  @apply text-white bg-black  border-[#3d3d3d];
}

.error-message {
  @apply text-red mt-1 text-[12px] md:text-sm font-semibold;
}

.read-only {
  @apply bg-grey-100 text-grey-light;
}

@media (max-width: 639px) {
  .generic-table-pagination {
    .mat-mdc-paginator-page-size {
      .mat-mdc-form-field {
        @apply m-0 w-[60px];

        .mat-mdc-text-field-wrapper {
          @apply px-2;
        }
      }
    }

    .mat-mdc-paginator-page-size-label {
      @apply hidden;
    }

    .mat-mdc-paginator-range-label {
      @apply mx-3;
    }
  }
}

.field-box {
  .iti__country-list {
    .iti__country {
      &.iti__active {
        @apply bg-primary-light text-primary font-semibold;
      }

      &.iti__highlight {
        @apply bg-grey-50;
      }
    }
  }
}

.btn-date-select {
  .mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 36px;
    @apply p-2;
  }
}

textarea {
  @apply h-32;
}

input::-ms-reveal {
  @apply hidden;
}

.custom-options-height {
  .ng-dropdown-panel-items.scroll-host {
    @apply max-h-[400px];
  }
}

input:-webkit-autofill {
  -webkit-text-fill-color: white;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
}
mat-datepicker-toggle {
  &.mat-datepicker-toggle {
    @apply text-white;
    .mat-mdc-button-base {
      @apply block p-0 h-auto w-auto;
    }
  }
}